import React from "react";
import Layout from "../../components/layout";
import Image from "../../components/elements/Image";
import {
  ImageAngle,
  ModelsHeader,
  ImageCenterRow,
  Text,
  CardTitle,
  CardLine,
  CardText,
  Card, CardImg, CardContent, CardList
} from "../../components/models/models.styles";
import { BlockGrey, BlockRow, BlockCard, BlockCardText, BlackGrid, BlockRow2, BlockRowContent, BlockRowImage, BlockRowImage2, BlockTitle, BlockText, RowCapacity, CardCapacity, ImgPrecise, BlockTech, RowTech, RowSound, CardSound, BlockGrey2, RowSecurity, CardSecurity, CardBottom } from "../../components/models/challenger.styles"
import { Title } from "../../styles/typography.styles";
import { Row } from "../../components/home/home.styles";
import ModelsCard from "../../components/models/models-card";
import * as DodgeJson from '../../data/dodge.json';

const Challenger = () => {
  const challenger  = DodgeJson.default.filter(item => item.model === "challenger");

  return (
    <Layout title={'Dodge Challenger'}>
      <ModelsHeader>
        <Image fileName={'challenger_top_banner@2x.jpg'} alt={"Dodge Challenger"} />
      </ModelsHeader>

      <main>
        <div className={`container`}>
          <Title>DODGE CHALLENGER TRIM</Title>

          <Row>
            <ModelsCard grid={5} title="Dodge Challenger SXT PLUS"
                        subtitle="SILNIK V6 3.6L – 305 KM"
                        image="CHALLENGER-SXT-PLUS_thumb@2x.jpg"
            />
            <ModelsCard grid={5} title="Dodge Challenger SCAT PACK WIDEBODY"
                        subtitle="SILNIK V8 6.4L – 485 KM"
                        image="challenger-rtscat-b5blue_thumb@2x.jpg"
            />
            <ModelsCard grid={5} title="Dodge Challenger SRT HELLCAT"
                        subtitle="SILNIK V8 6.2L – 717 KM"
                        image="Challenger-Hellcat_thumb@2x.jpg"
            />
            <ModelsCard grid={5} title="Dodge Challenger SRT HELLCAT REDYEYE WIDEBODY"
                        subtitle="SILNIK V8 6.2L – 797 KM"
                        image="Challenger-Redeye-widebody_thumb@2x.jpg"
            />
            <ModelsCard grid={5} title="Dodge Challenger SUPER STOCK"
                        subtitle="SILNIK V8 6.2L – 807 KM"
                        image="CHALLENGER_SUPER_STOCK_thumb@2x.jpg"
            />
          </Row>
        </div>

        <Title>ODKRYJ NA NOWO SWOJEGO CHALLENGERA</Title>

        <ImageAngle>
          <Image fileName="challenger_DISCOVER@2x.jpg" alt="Odkryj na nowo swojego Challengera"/>
        </ImageAngle>

        <div className="container">
          <Title>NIEOGRANICZONE MOŻLIWOŚCI</Title>

          <Text maxWidth={"60rem"}>
            Dodge Challenger 2020 jest bezpośrednim potomkiem legendarnej linii wyścigowej
            z nieziemską mocą.
          </Text>

          <ImageCenterRow>
            <div className="column">
              <Image fileName="2019-dodge-challenger-performance-keys@2x.jpg" alt="Dodge Challenger"/>
              <CardTitle>
                WYBIERZ <br/> SWOJĄ MOC
              </CardTitle>
              <CardLine/>
              <CardText>
                Użyj czarnego pilota i uzyskaj wyjątkowe osiągi podczas codziennej jazdy z mocą do 500 koni mechanicznych. Lub złap czerwony brelok i uwolnij pełny potencjał Dodge Challenger SRT® Hellcat o mocy do 717 koni mechanicznych.
              </CardText>
            </div>
            <div className="column">
              <Image fileName="2019-dodge-challenger-performance-launch-control@2x.jpg" alt="Dodge Challenger"/>
              <CardTitle align="right">
                DOSTĘPNA <br/> KONTROLA STARTOWA
              </CardTitle>
              <CardLine align="flex-end"/>
              <CardText align="right">
                Gotowy na uderzenie? Ten dokładnie skalibrowany program zapewnia lepsze przyspieszenie.
              </CardText>
            </div>
          </ImageCenterRow>
        </div>

        <BlockGrey>
          <Title>NIEZAWODNE HAMULCE</Title>

          <div className="container">
            <Text maxWidth={"60rem"} style={{marginBottom: '4rem'}}>
              Znaczące udoskonalenia technologii oraz osiągów są możliwe dzięki
              czterem wyjątkowym układom hamulcowym.
            </Text>

            <Image fileName="challenger_brakes@2x.png" alt="Dodge Challenger"/>

            <BlockRow>
              <BlockCard grid={4}>
                <CardTitle>
                  SPORTOWY <br/> UKŁAD HAMULCOWY
                </CardTitle>
                <CardLine />
                <BlockCardText>
                  Sportowy układ hamulcowy ma wentylowane przednie i tylne tarcze o przekątnej 12,6 cala. Jest to standardowe wyposażenie modelu Challenger SXT.
                </BlockCardText>
              </BlockCard>

              <BlockCard grid={4}>
                <CardTitle>
                  WYDAJNY <br/> UKŁAD HAMULCOWY
                </CardTitle>
                <CardLine />
                <BlockCardText>
                  Duże, przednie tarcze (13,6 cala) oraz zaciski poprawiają skuteczność hamowania. Dostępne standardowo w wersjach GT i R/T. Opcja Super Track Pak dodaje wydajne okładziny hamulcowe, aby zredukować zużycie hamulca w trudnych warunkach hamowania.
                </BlockCardText>
              </BlockCard>

              <BlockCard grid={4}>
                <CardTitle>
                  CZTEROTŁOCZKOWY UKŁAD HAMULCOWY BREMBO
                </CardTitle>
                <CardLine />
                <BlockCardText>
                  Z czterotłoczkowymi zaciskami Brembo i wentylowanymi tarczami hamulcowymi 14,2 cala (przód) i 13,8 cala (tył), Dodge Challenger R / T Scat Pack zapewnia drogę hamowania z 100-0 km/h w 36 m.
                </BlockCardText>
              </BlockCard>

              <BlockCard grid={4}>
                <CardTitle>
                  SZEŚCIOTŁOKOWY SYSTEM HAMULCOWY BREMBO
                </CardTitle>
                <CardLine />
                <BlockCardText>
                  Standardowo przedni układ hamulcowy w Dodge Challenger SRT® Hellcat jest największym układem hamulcowym, jaki kiedykolwiek oferowano w pojeździe produkcji firmy Fiat Chrysler Automobiles. Wyposażony w tarcze Brembo o średnicy 390 mm (15,4 cala) z sześciotłoczkowymi zaciskami.
                </BlockCardText>
              </BlockCard>
            </BlockRow>
          </div>
        </BlockGrey>

        <div className="container">
          <Title>PRAWDZIWIE MUSCULARNY</Title>

          <Text maxWidth={"60rem"} style={{marginBottom: '4rem'}}>
            Dodge SRT® Hellcat to ikona inżynierii o niekwestionowanej mocy. Dostępna tylko w tej klasie ośmiobiegowa automatyczna skrzynia biegów TorqueFlite® w połączeniu z silnikiem o mocy 717 koni mechanicznych zapewnia niewiarygodną jazdę.
          </Text>

          <Image fileName="challenger_muscle@2x.jpg" alt="Dodge Challenger"/>

          <BlockRow>
            <BlockCard grid={3}>
              <CardTitle>
                KONTROLA <br/> TRAKCJI
              </CardTitle>
              <CardLine />
              <BlockCardText>
                Kontrola trakcji pomaga utrzymać przyczepność i stabilność poprzez zastosowanie ciśnienia hamowania na ślizgające się koła, jednocześnie zmniejszając moc silnika.
              </BlockCardText>
            </BlockCard>

            <BlockCard grid={3}>
              <CardTitle>
                ELEMENTY <br/> NAPĘDOWE
              </CardTitle>
              <CardLine />
              <BlockCardText>
                Napęd na tylne koła i rozkład masy wynoszący prawie 50/50 zapewniają ekscytujące wrażenia z jazdy. Podczas przyspieszania ciężar przesuwa się na oś napędową, przez co zwiększa się siła docisku i tym samym poprawia przyczepność.
              </BlockCardText>
            </BlockCard>

            <BlockCard grid={3}>
              <CardTitle>
                OŚ <br/> ALUMINIOWA
              </CardTitle>
              <CardLine />
              <BlockCardText>
                W porównaniu do poprzedniej generacji lżejsze aluminiowe osie i obudowy pomagają zmniejszyć ciężar i tarcie
              </BlockCardText>
            </BlockCard>
          </BlockRow>
        </div>

        <BlackGrid>
          <Title color="white">Z ZEWNĄTRZ</Title>

          <div className="container">
            <BlockRow2>
              <BlockRowContent>
                <CardTitle color="white" align="right">
                  INSPIROWANE <br/> DZIEDZICTWEM
                </CardTitle>
                <CardLine align="flex-end" />
                <BlockCardText color="#e7e7e7;" align="right">
                  Od podświetlanych reflektorów Air-Catcher® po tylni spoiler, Dodge Challenger muskularnie oddaje hołd jego historycznemu DNA.
                </BlockCardText>
              </BlockRowContent>

              <BlockRowImage>
                <Image fileName="challenger_exterior_01@2x.png" alt="Dodge Challenger"/>
              </BlockRowImage>
            </BlockRow2>

            <BlockRow2 className={`reverse`}>
              <BlockRowContent>
                <CardTitle color="white">
                  KULTOWY <br/> KLASYK
                </CardTitle>
                <CardLine />
                <BlockCardText color="#e7e7e7;">
                  Od unikatowego otworu wlotu powietrza poprzez ogromne hamulce do kultowego kształtu karoserii, Dodge Challenger wzbudza nostalgię.
                </BlockCardText>
              </BlockRowContent>

              <BlockRowImage className={`wide`}>
                <Image fileName="challenger_exterior_02@2x.png" alt="Dodge Challenger"/>
              </BlockRowImage>
            </BlockRow2>
          </div>
        </BlackGrid>

        <div className="container">
          <Title>Wnętrze</Title>

          <Image fileName="challenger_interior_01@2x.jpg" alt="Dodge Challenger"/>

          <BlockRow2 style={{marginTop: '4rem'}}>
            <BlockRowImage2>
              <Image fileName="challenger_interior_Slider_01@2x.jpg" alt="Dodge Challenger"/>
            </BlockRowImage2>

            <BlockRowContent>
              <BlockTitle color="black">
                SILNY <br/> WEWNĄTRZ
              </BlockTitle>
              <CardLine />
              <BlockText color="black">
                Samochód zaprojektowaliśmy wokół najważniejszego elementu: kierowcy. Precyzyjnie wykonane wnętrze ma najlepszą w swojej klasie przestrzeń pasażera i jest wyposażone w technologię, która daje ci kontrolę. A dzięki komfortowym, ekskluzywnym fotelom dla maksymalnie pięciu osób, możesz zabrać swoją załogę na niezapomnianą przejażdżkę.
              </BlockText>
            </BlockRowContent>
          </BlockRow2>

          <Title>nieprzeciętna ŁADOWNOŚĆ</Title>

          <Text maxWidth={"40rem"} style={{marginBottom: '4rem'}}>
            Z największą w swojej klasie pojemnością bagażnika,
            Dodge Challenger oferuje nieprzeciętną ładowność.
          </Text>

          <RowCapacity>
            <CardCapacity>
              <Image fileName={"2020-dodge-challenger-interior-cargo-space@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>POJEMNOŚĆ <br/> ŁADUNKU</BlockTitle>
              <CardLine/>
              <BlockText>
                Bagażnik w Dodge Challenger dumnie prezentuje najlepszą w swojej klasie ładowność.
              </BlockText>
            </CardCapacity>
            <CardCapacity>
              <Image fileName={"2020-dodge-challenger-interior-interior-space@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>PRZESTRZEŃ <br/> WEWNĘTRZNA</BlockTitle>
              <CardLine/>
              <BlockText>
                Dodge Challenger oferuje również najlepszą w swojej klasie przestrzeń pasażerską.
              </BlockText>
            </CardCapacity>
            <CardCapacity>
              <Image fileName={"2020-dodge-challenger-interior-five-passanger-seating@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>WSPÓLNA, <br/> KOMFORTOWA JAZDA</BlockTitle>
              <CardLine/>
              <BlockText>
                Dzięki ekskluzywnym siedzeniom dla pięciu pasażerów, Ty i twoi znajomi możecie wygodnie jeżdzić jednym samochodem
              </BlockText>
            </CardCapacity>
          </RowCapacity>

          <Title>PRECYZYJNE WYKONANIE</Title>

          <Text maxWidth={"40rem"} style={{marginBottom: '4rem'}}>
            Kultowe wskaźniki Tic-Toc-Tach otaczają 7-calowy, konfigurowalny,
            cyfrowy wyświetlacz informacyjny kierowcy.
          </Text>

          <ImgPrecise>
            <Image fileName={"2020-dodge-challenger-interior-carefully-customized@2x.png"} alt={"Dodge Challenger"}/>
          </ImgPrecise>
        </div>

        <BlockTech>
          <div className="container">

            <Title color="white">Technologia</Title>
            <BlockRow2>
              <BlockRowImage>
                <Image fileName={"challenger_technology_01@2x.jpg"} alt="Dodge challenger"/>
              </BlockRowImage>

              <BlockRowContent>
                <CardTitle color="white">
                  INTELIGENTNY <br/>NA KAŻDYM KROKU
                </CardTitle>
                <CardLine />
                <BlockCardText color="#e7e7e7;">
                  Technologia odróżnia liderów od naśladowców. Sprawia, że muscle cary są potężniejsze i oferują jeszcze lepszą radość z jazdy
                </BlockCardText>
              </BlockRowContent>
            </BlockRow2>
          </div>
        </BlockTech>

        <div className="container">
          <RowTech>
            <CardCapacity>
              <Image fileName={"2019-dodge-challenger-vlp-technology-steering-wheel@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>INTELIGENTNY <br/> SPOSÓB JAZDY</BlockTitle>
              <CardLine/>
              <BlockText>
                Dodge Challenger oddaje w twoje ręce najnowocześniejszą technologię, która ma ci zapewnić maksymalną dawkę radości i bezpieczeństwa.
              </BlockText>
            </CardCapacity>
            <CardCapacity>
              <Image fileName={"2019-dodge-challenger-vlp-technology-digital-cluster@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>DOSTOSUJ <br/> JAZDĘ</BlockTitle>
              <CardLine/>
              <BlockText>
                7-calowy, cyfrowy wyświetlacz kierowcy pozwala na manualne konfigurowanie wyświetlanych informacji, tak byś mógł je idealnie dopasować do swoich potrzeb.
              </BlockText>
            </CardCapacity>
            <CardCapacity>
              <Image fileName={"2019-dodge-challenger-vlp-technology-touchscreen@2x.jpg"} alt={"Dodge Challenger"}/>
              <BlockTitle>OGROMNY <br/> KOMPUTER POKŁADOWY</BlockTitle>
              <CardLine/>
              <BlockText>
                Dostępny Uconnect® 4C NAV jest wyposażony w największy w swojej klasie 8,4-calowy ekran dotykowy z ulubionymi aplikacjami, a także nawigację z grafiką 3D.
              </BlockText>
            </CardCapacity>
          </RowTech>

          <ImageAngle>
            <Image fileName={"challenger_technology_02@2x.jpg"} alt="Dodge Challenger"/>
          </ImageAngle>

          <Title>DŹWIĘKI WYSOKIEJ JAKOŚĆI</Title>

          <Text maxWidth={"40rem"} style={{marginBottom: '4rem'}}>
            Każda podróż ma swój hymn, wybierz więc odpowiednie głośniki
            i puść ulubioną muzykę
          </Text>

          <Image fileName={"sound@2x.jpg"} alt="Dodge Challenger"/>

          <RowSound>
            <CardSound>
              <BlockTitle>ALPINE® AUDIO GROUP</BlockTitle>
              <CardLine/>
              <BlockText>
                System Alpine® Audio Group jest wyposażony w dziewięć głośników Alpine, subwoofer i wzmacniacz o mocy 506 W.
              </BlockText>
            </CardSound>
            <CardSound>
              <BlockTitle>HARMAN KARDON® AUDIO GROUP</BlockTitle>
              <CardLine/>
              <BlockText>
                Niesamowity dźwięk płynący z 18 głośników, dwóch subwooferów oraz wzmacniacza o mocy 900 watów.
              </BlockText>
            </CardSound>
          </RowSound>
        </div>

        <BlockGrey2>
          <Title>BEZPIECZEŃSTWO</Title>

          <Text maxWidth={"60rem"} style={{marginBottom: '4rem'}}>
            Jeśli chodzi o bezpieczeństwo kierowcy to nie ma miejsca na błędy.
            Dodge Challenger posiada systemy, które pomogą Ci, gdy coś pójdzie nie po twojej myśli.
          </Text>

          <div className="image container">
            <Image fileName={"Challenger_safety@2x.png"} alt="Dodge Challenger"/>
          </div>
        </BlockGrey2>

        <RowSecurity className={`container`}>
          <CardSecurity>
            <BlockTitle>AUTOMATYCZNE ŚWIATŁA DROGOWE</BlockTitle>
            <CardLine/>
            <BlockText>
              Światła drogowe przełączają się automatycznie na światła mijania w momencie wykrycia przez czujniki reflektorów nadjeżdżającego pojazdu.
            </BlockText>
          </CardSecurity>
          <CardSecurity>
            <BlockTitle>MONITOROWANIE <br/> TOCZENIA</BlockTitle>
            <CardLine/>
            <BlockText>
              System monitorowania martwego pola pomaga zmienić pas, ostrzegając cię, gdy sąsiedni pojazd wjedzie do jednej z nieobjętych twoim wzrokiem stref martwego pola.
            </BlockText>
          </CardSecurity>
          <CardSecurity>
            <BlockTitle>ADAPTACYJNY <br/> TEMPOMAT</BlockTitle>
            <CardLine/>
            <BlockText>
              System automatycznie dostosowuje prędkość tak, aby pomóc utrzymać ustaloną odległość od poprzedzającego pojazdu.
            </BlockText>
          </CardSecurity>
          <CardSecurity>
            <BlockTitle>TYLNA KAMERA <br/> PARKVIEW®</BlockTitle>
            <CardLine/>
            <BlockText>
              Tylna kamera cofania ParkView® pomaga zobaczyć, co dzieje się za Tobą na ekranie dotykowym Uconnect®, gdy zmieniasz bieg na wsteczny. Linie siatki pomagają w manewrowaniu na parkingach lub w wąskich obszarach.
            </BlockText>
          </CardSecurity>
          <CardSecurity>
            <BlockTitle>OSTRZEŻENIE PRZED KOLIZJĄ</BlockTitle>
            <CardLine/>
            <BlockText>
              Dostępne ostrzeżenie przed kolizją przy pełnej prędkości przesyła ostrzeżenia dźwiękowe i wizualne oraz w razie potrzeby hamuje pojazd.
            </BlockText>
          </CardSecurity>
        </RowSecurity>

        <div className="container">
          <Title>BĄDŹ PRZYGOTOWANY</Title>

          <Text maxWidth={"90rem"} style={{marginBottom: '4rem'}}>
            Czasami rzeczy dzieją się bez ostrzeżenia. Dodge Challenger został tak zaprojektowany, aby zapewnić Tobie i Twoim pasażerom bezpieczeństwo w każdej sytuacji.
          </Text>

          <Image fileName={"challenger-safety-structure@2x.jpg"} alt="Dodge Challenger"/>

          <RowSecurity>
            <CardBottom>
              <BlockTitle>STREFA <br/> ZGNIOTU</BlockTitle>
              <CardLine/>
              <BlockText>
                Specjalnie zaprojektowane przednie i tylne strefy zgniotu pomagają absorbować energię uderzenia i kierować ją z dala od pasażerów, zmiejszając tym samym ryzyko obrażeń.
              </BlockText>
            </CardBottom>
            <CardBottom>
              <BlockTitle>SOLIDNA <br/> RAMA</BlockTitle>
              <CardLine/>
              <BlockText>
                Podstawą nadwozia jest solidna konstrukcja zbudowana ze stali o wysokiej wytrzymałości. Wysoka wytrzymałość dachu i stalowa rama bezpieczeństwa pomagają chronić kabinę.
              </BlockText>
            </CardBottom>
            <CardBottom>
              <BlockTitle>PODUSZKI <br/> POWIETRZNE</BlockTitle>
              <CardLine/>
              <BlockText>
                W wyposażeniu standardowym sa dostępne zaawansowane, wielostopniowe poduszki powietrzne kierowcy i pasażera z przodu, dodatkowe przednie i tylne boczne poduszki powietrzne oraz boczne poduszki powietrzne.
              </BlockText>
            </CardBottom>
          </RowSecurity>
        </div>

        <BlackGrid>
          <Title color="white">W twojej obronie</Title>

          <div className="container">
            <Image fileName={"challenger-safety-spring@2x.png"} alt="Dodge Challenger"/>

            <RowSecurity>
              <CardBottom>
                <BlockTitle color="white">AUTOMATYCZNE WYCIERACZKI PRZECIWDESZCZOWE</BlockTitle>
                <CardLine/>
                <BlockText color="#e7e7e7">
                  Wycieraczki z funkcją wykrywania deszczu wykrywają wilgoć na przedniej szybie i włączają się automatycznie.
                </BlockText>
              </CardBottom>
              <CardBottom>
                <BlockTitle color="white">WSPARCIE <br/> HAMULCA</BlockTitle>
                <CardLine/>
                <BlockText color="#e7e7e7">
                  Wspomaganie hamulca deszczowego usuwa wodę z klocków hamulcowych, zbliżając niewielkim naciskiem klocek hamulcowy do tarczy. Włącza się, gdy wycieraczki przedniej szyby są aktywne.
                </BlockText>
              </CardBottom>
              <CardBottom>
                <BlockTitle color="white">AKTYWNE ZAGŁÓWKI <br/> PRZEDNICH FOTELI </BlockTitle>
                <CardLine/>
                <BlockText color="#e7e7e7">
                  Aktywne zagłówki z przodu pomagają zmniejszyć ryzyko odniesienia obrażeń przez kierowcę i pasażera z przodu w przypadku uderzeń w tył pojazdu.
                </BlockText>
              </CardBottom>
            </RowSecurity>
          </div>
        </BlackGrid>

        <div className="container">
          {challenger.map(model => (
            <Card key={model.id}>
              <CardImg>
                <Image fileName={model.image} alt={model.title}/>
              </CardImg>
              <CardContent>
                <CardTitle>{model.title}</CardTitle>
                <CardLine/>

                <CardList>
                  {model.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </CardList>
              </CardContent>
            </Card>
          ))}
        </div>

      </main>
    </Layout>
  )
}

export default Challenger